<template>
  <div class="bg-img min-height flex-column justify-content-start align-items-center">
    <div class="block3 box-shadow bg-white width-100 br-reg">
      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="fw-mid">我的报告</p>
        </div>
        <div @click="jumpTo('/mandatoryReportingForm')" class="width-40 textAlign-r">
          <img src="@/assets/image/pic1.png" height="30rem" alt="" />
        </div>
      </div>
      <van-list>
        <van-pull-refresh>
          <div @click="jumpToInfo(item.id)" class="list-box position-relative margin-bs" v-for="(item, index) in list" :key="index">
            <div v-if="item.reportStatus === 1" class="label label1 white position-absolute">处理中</div>
            <div v-else-if="item.reportStatus === 2" class="label label2 white position-absolute">已办结</div>
            <div v-else class="label label3 white position-absolute">未回复</div>
            <div class="width-70 flex-row justify-content-start align-items-center">
              <p class="fs-mid black margin-rs fw-mid">{{ item.childName }}</p>
              <p class="fs-mid darkgrey margin-ls">{{ item.createTime }}</p>
            </div>
            <p class="fs-mid width-100 darkgrey margin-ts cut-text">就诊病例：{{ item.diagnoses }}</p>
            <p class="fs-mid darkgrey margin-ts cut-text2">{{ item.reportContent }}</p>
          </div>
        </van-pull-refresh>
      </van-list>
    </div>
    <div class="block1 bg-white position-relative"></div>
    <div class="block2 bg-white position-relative"></div>
  </div>
</template>
<script>
import { mandatoryReportList } from '@/api/reportApi';
export default {
  name: 'mandatoryReportingList',
  data() {
    return {
      zlbId: '',
      userName: '',
      userMobile: '',
      list: []
    };
  },
  created() {
    let zlbId = this.$Cookies.get('zlbId');

    if (zlbId == null) {
      this.$router.push('/mandatoryReportingIndex');
    }

    this.zlbId = zlbId;
    this.userName = this.$Cookies.get('userName');
    this.userMobile = this.$Cookies.get('userMobile');
    this.initListData(zlbId);
  },
  methods: {
    jumpTo(e) {
      this.$router.push(e);
    },
    jumpToInfo(id) {
      this.$router.push({
        path: '/mandatoryReportingInfo',
        query: {
          id: id
        }
      });
    },
    initListData(zlbId) {
      mandatoryReportList({ zlbId: zlbId }).then((response) => {
        this.list = response.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.bg-img {
  background: url('../../assets/image/bg3.jpg') center top no-repeat;
  background-size: 100%;
  background-color: #4f9eea;
  width: 100%;
  padding: 14rem 5% 2rem;
}
.block1 {
  width: 80%;
  height: 0.8rem;
  border-radius: 2rem 2rem 0 0;
  opacity: 0.25;
}
.block2 {
  width: 90%;
  height: 0.8rem;
  border-radius: 2rem 2rem 0 0;
  opacity: 0.4;
}
.block3 {
  padding: 2rem 5%;
  .title {
    line-height: 2rem;
    //background: url("../../assets/image/5.png") left bottom no-repeat;
    //background-size: contain;
    p {
      color: #4f9eea;
      font-size: 1.5rem;
    }
  }
  .list-box {
    width: 100%;
    padding: 1rem;
    background: rgb(233, 246, 255);
    .label {
      right: 0;
      top: 0.5rem;
      font-size: 0.8rem;
      padding: 0.1rem 0.5rem 0.1rem 1rem;
    }
    .label1 {
      background: url('../../assets/image/pic3.png') center no-repeat;
      background-size: 100%;
    }
    .label2 {
      background: url('../../assets/image/pic4.png') center no-repeat;
      background-size: 100%;
    }
    .label3 {
      background: url('../../assets/image/pic5.png') center no-repeat;
      background-size: 100%;
    }
  }
}
.block1 {
  width: 100%;
  height: 1.5rem;
  border-radius: 0 0 0.8rem 0.8rem;
  opacity: 0.3;
  top: -0.5rem;
}
.block2 {
  width: 100%;
  height: 2.2rem;
  border-radius: 0 0 0.8rem 0.8rem;
  opacity: 0.2;
  margin-bottom: 3rem;
  top: -1.8rem;
}
</style>
